<template>
  <se-input v-bind="$attrs" v-model="value" :type="reveal ? 'text' : 'password'">
    <template v-if="$slots.prefix" #prefix>
      <slot name="prefix" />
    </template>
    <template #suffix>
      <div @click="reveal = !reveal">
        <se-icon :name="reveal ? 'eye-off' : 'eye'" />
      </div>
      <slot name="suffix" />
    </template>
  </se-input>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import SeInput from '../input/Input';
import SeIcon from '../icon/Icon';

const props = defineProps<{
  modelValue: string,
}>();

const emit = defineEmits<{(e: 'update:modelValue', value: string): any}>();

const value = computed<string>({
  get() {
    return props.modelValue;
  },
  set(val: string) {
    emit('update:modelValue', val);
  },
});

const reveal = ref<boolean>(false);
</script>

<script lang="ts">
export default {
  name: 'CrInputPassword',
};
</script>
